body[data-checkout-release='1'],
body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    .confirm-progress-bar {
      border-bottom: solid 4px $black;
      display: flex;
      font-weight: bold;
      margin-bottom: 20px;
      padding-bottom: 5px;
      padding-#{$rdirection}: 10px;
      width: 100vw;
      @include breakpoint($medium-up) {
        width: 70%;
      }
      .confirm-progress-item {
        flex: 1;
        text-align: center;
      }
      &__item {
        display: inline-block;
        height: 20px;
        width: 20px;
        @include breakpoint($medium-up) {
          height: 24px;
          width: 24px;
        }
      }
    }
    &.confirmation-page {
      @include breakpoint($medium-up) {
        display: flex;
      }
      .column {
        &.left {
          border-top-width: 0;
          .confirmation-panel {
            .transaction-identifier {
              display: flex;
              margin: 12px 0;
              text-align: #{$ldirection};
              &__id,
              &__date {
                flex: 1 1 auto;
                margin: 0;
              }
            }
          }
          .sign-in-confirmation-panel {
            width: 100%;
            @include breakpoint($medium-up) {
              width: 80%;
            }
            .btn__submit {
              width: 100%;
              @include breakpoint($medium-up) {
                width: 60%;
              }
            }
            .content {
              padding-top: 15px;
            }
            .form-item {
              display: grid;
            }
            .forgot__pw--note {
              margin-bottom: 35px;
            }
            .password__field {
              margin-top: 18px;
              width: 100%;
              input[type='password'],
              input[type='text'] {
                height: 40px;
              }
              @include breakpoint($medium-up) {
                width: 80%;
              }
              .label {
                margin-bottom: 5px;
              }
              .toggle-password {
                top: 15px;
              }
            }
          }
          .registration-panel {
            .how--we-will-use-container {
              margin-bottom: 10px;
              margin-top: 10px;
            }
            .email__filed {
              display: grid;
            }
            .password-field {
              margin-bottom: 18px;
              margin-top: 18px;
              &__info {
                padding-#{$ldirection}: 25px;
              }
              input[type='password'],
              input[type='text'] {
                height: 40px;
                width: 100%;
                @include breakpoint($medium-up) {
                  width: 60%;
                }
              }
            }
            .toggle-password {
              top: 12px;
              @include breakpoint($medium-up) {
                #{$rdirection}: 310px;
              }
            }
            .terms-and-conditions {
              margin-bottom: 15px;
              width: 100%;
              @include breakpoint($medium-up) {
                width: 80%;
              }
            }
            .create-account {
              input[type='submit'] {
                width: 100%;
                @include breakpoint($medium-up) {
                  width: 40%;
                }
              }
            }
          }
          .confirm-item-wrapper {
            display: flex;
            padding: 15px 0;
            &__link {
              text-decoration: none;
            }
            .thumb {
              flex: 1;
              padding-#{$rdirection}: 10px;
            }
            .desc {
              flex: 5;
            }
            .total {
              flex: 1;
              padding-#{$rdirection}: 10px;
              text-align: #{$rdirection};
            }
          }
          .guarantee-panel {
            padding: 24px 12px;
            .title--h5 {
              margin: 0;
            }
            &__desc {
              margin: 10px 0;
            }
            @include breakpoint($medium-up) {
              margin: 24px 0;
            }
          }
        }
        &.right {
          .order-summary-panel {
            margin-top: 40px;
            @include breakpoint($medium-up) {
              margin: 0 0 24px;
            }
          }
        }
      }
    }
  }
}
