// line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
