body[data-checkout-release='1'] {
  #offer-code-panel-loyalty {
    border-bottom: 1px solid $color-light-gray;
    &.panel header {
      background: $color-white;
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    .checkout__offer-code__wallet {
      border: 1px solid $color-grey;
      margin: 10px 0;
      padding: 20px;
      width: 100%;
      &-count {
        padding-bottom: 0px;
      }
      &_controls {
        .slick-dots {
          bottom: 0;
          margin: 0 10%;
          position: relative;
          width: 80%;
          li {
            margin: 0 6px;
            button {
              &::before {
                border-radius: 50%;
                font-size: 12px;
              }
            }
          }
        }
        .slide-arrow {
          border: none;
          display: block;
          height: 1.1em;
          margin-top: 5px;
          width: 1.5em;
        }
        .slide-arrow.slick-disabled {
          opacity: 0.2;
        }
        .slide-arrow.prev-arrow {
          background: url('../../../../all/themes/elc_base/img/icons/src/arrow_#{$ldirection}_new_lux.svg') 0 0 no-repeat;
          float: #{$ldirection};
        }
        .slide-arrow.next-arrow {
          background: url('../../../../all/themes/elc_base/img/icons/src/arrow_#{$rdirection}_new_lux.svg') 0 0 no-repeat;
          float: #{$rdirection};
        }
      }
      .checkout__offer-code__wallet {
        &-item {
          font-size: 14px;
          text-align: unset;
          &.slick-slide {
            margin-#{$rdirection}: 0;
          }
          &-container {
            display: flex;
            min-height: 110px;
          }
          &__image-container {
            flex-shrink: 0;
            margin: auto;
            width: 30%;
          }
          &__inner {
            padding-#{$ldirection}: 10px;
          }
          &__image {
            display: inline-block;
            height: unset;
            width: unset;
            &__title { // in brand styling
              align-items: center;
              background: $color-black;
              color: $color-white;
              display: flex;
              height: 95px;
              justify-content: center;
              letter-spacing: 0.02em;
              line-height: 1.1;
              padding: 5px;
              @media (max-width: $extra-large-up) {
                height: 110px;
              }
              @media (max-width: $extra-extra-large-up) {
                height: 150px;
              }
            }
          }
          &__header {
            line-height: 1.7;
            font-size: 16px;
            font-weight: 700;
            &_subheading {
              font-size: 12px;
              font-weight: 700;
            }
          }
          &__button-container {
            margin-top: 15px;
            padding: 0 1px;
          }
          &.is-applied {
            .checkout__offer-code__wallet {
              &-apply {
                display: none;
              }
              &-remove {
                color: $color-white;
                display: inline-block;
                padding-top: 10px;
                text-decoration: none;
              }
              &-selected {
                display: inline-block;
              }
              &-item__promo-applied {
                display: block;
                font-size: 12px;
                margin-top: 20px;
              }
            }
          }
          &__button-loader {
            background: url('../../../../all/themes/elc_base/img/icons/src/ajax-loader.gif') no-repeat center center $color-white;
            border: 1px solid $color-black;
            display: none;
            height: 100%;
            position: absolute;
            width: 99%;
            z-index: 10;
          }
          &__promo-applied {
            display: none;
          }
        }
        &-apply {
          background: $color-white;
          border: 1px solid $color-black;
          color: $color-black;
          display: inline-block;
        }
        &-remove {
          border: none;
          display: none;
          height: 0;
          line-height: inherit;
          text-decoration: underline;
        }
        &-selected {
          background: $color-black;
          color: $color-white;
          display: none;
          pointer-events: none;
        }
        &-apply,
        &-remove {
          color: $color-black;
          font-weight: 500;
          height: 40px;
          line-height: 1.2;
          padding-bottom: 11px;
          padding-top: 10px;
          width: 100%;
        }
      }
      &-wrapper {
        padding: 5px;
      }
    }
  }
}
