body[data-checkout-release='1'] {
  .pg_wrapper--viewcart {
    .cart-items {
      margin: 30px 0 64px;
      padding: 30px 0 0;
      .cart-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        position: relative;
        &__content {
          display: flex;
        }
        &__details {
          flex: 1;
        }
        &__form {
          display: flex;
        }
        &__thumb {
          height: 100px;
          margin-#{$rdirection}: 30px;
          width: 100px;
          &-link {
            display: block;
          }
        }
        &__title {
          margin-bottom: 12px;
          padding-#{$rdirection}: 40px;
          &-link {
            display: inline-block;
            text-decoration: none;
          }
          &-sub-text {
            margin-top: 4px;
          }
        }
        &__footer {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          @include breakpoint($medium-up) {
            flex: 1;
          }
        }
        &__quantity {
          order: 2;
          width: 100%;
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 8px;
            order: initial;
            width: auto;
          }
          &-input {
            padding: 0;
            text-align: center;
            width: 40px;
          }
          &-button {
            background: transparent;
            cursor: pointer;
            height: 40px;
            position: relative;
            text-indent: -999rem;
            width: 40px;
            &::before {
              #{$ldirection}: 13px;
              content: '';
              height: 2px;
              position: absolute;
              top: 19px;
              width: 12px;
            }
            &--increase {
              &::after {
                #{$ldirection}: 18px;
                content: '';
                height: 12px;
                position: absolute;
                top: 14px;
                width: 2px;
              }
            }
          }
        }
        &__quantity-price {
          display: flex;
          flex-direction: column;
          margin-top: 12px;
          @include breakpoint($medium-up) {
            flex-direction: row;
            margin-top: 8px;
          }
        }
        &__price,
        &__total {
          margin-bottom: 8px;
          @include breakpoint($medium-up) {
            margin-bottom: 0;
          }
        }
        &__price {
          flex: 1;
          @include breakpoint($medium-up) {
            order: 2;
          }
        }
        &__total {
          @include breakpoint($medium-up) {
            order: 3;
          }
        }
        &__remove {
          #{$rdirection}: 0;
          position: absolute;
          &-button {
            #{$rdirection}: -17px;
            background: transparent;
            border: 0;
            cursor: pointer;
            height: 48px;
            min-width: auto;
            padding: 0;
            position: relative;
            text-indent: -999rem;
            top: -17px;
            width: 48px;
            &::before,
            &::after {
              #{$rdirection}: 50%;
              content: '';
              height: 1px;
              position: absolute;
              top: 50%;
              width: 14px;
            }
            &::before {
              transform: translate(50%, -50%) rotate(45deg);
            }
            &::after {
              transform: translate(50%, -50%) rotate(-45deg);
            }
          }
        }
        &--sample {
          .cart-item {
            &__price,
            &__quantity {
              display: none;
            }
            &__total {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}

body[data-checkout-release='2'] {
  .pg_wrapper--viewcart {
    .cart-items {
      margin: 16px 0 0;
      padding: 20px 16px;
      .cart-item {
        margin-bottom: 30px;
        padding: 0 16px 30px 0;
        position: relative;
        @include breakpoint($medium-up) {
          padding: 0 16px 30px 16px;
        }
        &__content {
          display: flex;
          flex-wrap: wrap;
        }
        &__details {
          flex: 1;
          .collection-item {
            .kit-sub-item {
              display: flex;
              margin-bottom: 16px;
            }
            .swatch {
              border-radius: 25px;
              display: inline-block;
              height: 16px;
              margin-#{$rdirection}: 5px;
              vertical-align: top;
              width: 16px;
            }
            .kit-item-image {
              height: 40px;
              margin-#{$rdirection}: 16px;
              width: 40px;
            }
          }
        }
        &__form {
          display: flex;
        }
        &__thumb {
          height: 100px;
          margin-#{$rdirection}: 3px;
          width: 75px;
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 30px;
            width: 100px;
          }
          &-link {
            border: none;
            display: block;
          }
        }
        &__remove {
          flex: 1 1 100%;
          margin-#{$ldirection}: 80px;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 130px;
          }
        }
        &__title {
          font-size: get-rem(16px);
          margin-bottom: 12px;
          margin-top: 0;
          padding-#{$rdirection}: 40px;
          &.gift-title {
            letter-spacing: 0;
            text-transform: initial;
          }
          &-link {
            border: none;
            text-decoration: none;
          }
          &-text {
            letter-spacing: 0;
            text-transform: uppercase;
          }
          &-sub-text {
            margin-top: 4px;
          }
        }
        &__color {
          padding-#{$rdirection}: 10px;
        }
        &__footer {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          @include breakpoint($medium-up) {
            flex: 1;
          }
          .price {
            margin-#{$rdirection}: 3px;
            @include breakpoint($medium-up) {
              margin-#{$rdirection}: 10px;
            }
          }
          &.qty_price_wrap {
            margin-top: 5px;
          }
        }
        &__quantity {
          order: 2;
          width: 100%;
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 8px;
            order: initial;
            width: auto;
          }
          &-input {
            padding: 0;
            text-align: center;
            width: 35px;
          }
          &-button {
            background: transparent;
            border-radius: 15px;
            cursor: pointer;
            height: 24px;
            position: relative;
            text-indent: -999rem;
            width: 24px;
            &::before {
              #{$ldirection}: 5px;
              content: '';
              height: 2px;
              position: absolute;
              top: 11px;
              width: 12px;
            }
            &--increase {
              &::after {
                #{$ldirection}: 10px;
                content: '';
                height: 10px;
                position: absolute;
                top: 7px;
                width: 2px;
              }
            }
          }
          .qty_counter {
            margin-top: 7px;
          }
        }
        &__shade_name_row {
          display: flex;
          margin-bottom: 10px;
          text-transform: capitalize;
          .cart-item__color {
            & + .cart-item__size {
              padding: 0 10px;
            }
          }
        }
        &__quantity-price {
          display: flex;
          flex-direction: column;
          justify-content: #{$rdirection};
          margin: 8px 0;
          @include breakpoint($medium-up) {
            flex-direction: row;
            margin-top: 8px;
          }
        }
        &__price,
        &__total {
          margin-bottom: 8px;
          @include breakpoint($medium-up) {
            margin-bottom: 0;
          }
        }
        &__price {
          flex: 1;
          @include breakpoint($medium-up) {
            order: 2;
          }
          .strike-through-price {
            margin-#{$rdirection}: 2px;
            text-decoration: line-through;
            @include breakpoint($medium-up) {
              margin-#{$rdirection}: 7px;
            }
          }
        }
        &__total {
          margin-#{$ldirection}: auto;
          @include breakpoint($medium-up) {
            order: 3;
          }
          &.total {
            margin-top: auto;
          }
        }
        &__remove {
          &-button {
            background: transparent;
            border: none;
            border-radius: 0;
            cursor: pointer;
            font-weight: bold;
            height: auto;
            text-transform: uppercase;
            padding: 0;
            &::before,
            &::after {
              content: none;
            }
          }
        }
        &--sample {
          .cart-item {
            &__price,
            &__quantity {
              display: none;
            }
            &__total {
              margin-top: 12px;
            }
          }
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .kit_quantity {
          .qty_counter {
            margin-top: 0;
          }
        }
      }
    }
  }
}
