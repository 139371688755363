body[data-checkout-release='1'] {
  .checkout-header {
    margin: 16px 0 6px;
    width: 100%;
  }
}
body[data-checkout-release='2'] {
  .checkout-header {
    margin: 16px 0 6px;
    width: 100%;
    .item-count {
      &::before {
        #{$ldirection}: 5px;
        content: '\0028';
        position: relative;
      }
      &::after {
        #{$rdirection}: 5px;
        content: '\0029';
        position: relative;
      }
    }
  }
  .viewcart-panel {
    padding-top: 0;
  }
  .hide_panel {
    display: none;
  }
  .viewcart-buttons-panel-empty-cart{
    .content {
      display: flex;
      flex-direction: column;
      @include breakpoint($medium-up) {
        align-items: center;
        column-gap: 25px;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
      }
      .best-sellers-button,
      .homepage-button {
        flex: 1;
        .button {
          height: 48px;
          padding: 17px 20px;
        }
      }
    }
  }
}
