body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    .panel--order-summary {
      .order-item {
        display: flex;
        margin-top: 10px;
        &__label {
          flex: 1;
          margin-#{$rdirection}: 40px;
        }
      }
    }
  }
}
body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    .panel--order-summary {
      padding: 16px;
      .order-item {
        display: flex;
        margin-top: 10px;
        &__label {
          flex: 1;
          margin-#{$rdirection}: 40px;
        }
      }
      .klarna-account--cart,
      .order-summary-content__afterpay {
        align-items: center;
        column-gap: 6px;
        display: flex;
        margin: 15px 0 0;
      }
      .order-summary-content__afterpay {
        padding-top: 15px;
        .close-link {
          display: none;
        }
        .single-message {
          align-items: center;
          column-gap: 6px;
          display: flex;
          margin: 15px 0 0;
        }
      }
      .installment-price__content {
        margin-top: 15px;
        & + .order-summary-content__afterpay {
          border-top: none;
          padding-top: 0;
        }
      }
      .klarna-account--cart {
        .klarna-account__learn-more {
          align-items: center;
          color: transparent;
          width: auto;
          &::after {
            background: $color-black;
            border-radius: 25px;
            height: 18px;
            line-height: get-line-height(11px, 20px);
            text-align: center;
            width: 18px;
          }
        }
      }
      .overlay-wrapper,
      .afterpay-learnmore {
        cursor: pointer;
        font-weight: bold;
        margin-#{$ldirection}: auto;
        padding: 2px 0;
      }
      .afterpay-learnmore {
        background: $color-black;
        border-radius: 25px;
        color: $color-white;
        height: 18px;
        text-align: center;
        text-transform: lowercase;
        width: 18px;
      }
    }
    .offer-code-panel {
      margin-top: 0;
      padding: 16px;
    }
    .need-help-panel {
      .launch-chat {
        position: relative;
        z-index: -1;
      }
    }
  }
  .applepay-terms-container {
    margin: 0 auto;
    width: 94%;
    .form-item {
      margin: -30px 0;
    }
    .picker {
      .picker {
        &-checkbox {
          width: 100%;
        }
        &-handle {
          height: 15px;
          margin-#{$rdirection}: 1%;
          max-width: 15px;
          width: 5%;
        }
      }
    }
    .terms_input {
      &.picker-label {
        font-size: get-rem(13px);
        max-width: 93%;
        text-align: justify;
      }
    }
  }
  .overlay-container {
    .klarna-information {
      padding: 20px;
      &__header {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 65px;
        margin-bottom: 25px;
        text-indent: -999em;
        width: 120px;
      }
      &__list {
        margin-bottom: 25px;
        margin-#{$ldirection}: 30px;
      }
      &__list-item {
        align-items: baseline;
        display: flex;
        margin-bottom: 10px;
        &::before {
          content: '.';
          flex-basis: 20px;
          padding-#{$rdirection}: 10px;
        }
      }
      &__sub-text {
        margin: 0;
        .klarna-information__link {
          border: 0;
          border-radius: 0;
          font-size: inherit;
          height: auto;
          line-height: inherit;
          padding: 0;
          text-decoration: underline;
          text-transform: none;
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
}
