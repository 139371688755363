body[data-checkout-release='1'] {
  .shipmethod-panel {
    border-width: 1px 0;
    margin: 0 0 40px;
    padding: 15px 0 0;
    .content {
      padding: 0px;
    }
    .ship-method-group-label {
      display: block;
      float: unset;
      .shipmethod-group {
        display: block;
        margin-bottom: 10px;
        width: 100%;
      }
    }
    .form-item label {
      display: none;
    }
    .delivery-options {
      border: none;
      padding: 0;
    }
    @include breakpoint($medium-up) {
      border-width: 0;
      .ship-method-group-label {
        display: flex;
        padding: 0px;
        justify-content: space-between;
        width: 100%;
        .shipmethod-group {
          padding: 15px;
          text-align: #{$ldirection};
          width: calc(50% - 5px);
        }
      }
      .delivery-options {
        padding: 0 15px;
      }
    }
  }
  .right {
    .delivery-date-wrapper,
    .delivery-time-wrapper {
      .visuallyhidden, //visually hidden: user cannot see but screenreader will read
      label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }
    .delivery_options_select {
      .selectbox {
        appearance: none;
        background: url('#{$base-theme-path}/img/icons/src/caret--down.svg') no-repeat center #{$rdirection};
        background-position-x: 97%;
        background-position-y: 70%;
        background-size: 18px;
        height: 48px;
        width: 100%;
        .selectBox-label {
          height: 54px;
        }
        &.selectBox-menuShowing{
          .selectBox-arrow {
            transform: translateX(-7px) rotate(180deg);
          }
        } 
      }
    }
  }
}
