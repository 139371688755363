body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    .gwp-samples {
      margin: 0 auto 16px;
      padding: 16px 16px 56px;
      @include breakpoint($medium-up) {
        padding: 16px;
      }
      &__header-text {
        margin-bottom: 4px;
        min-height: 40px;
      }
      &__remaining {
        margin-bottom: 40px;
        &-maximum,
        &-available {
          margin: 0;
        }
      }
      &__thumb {
        height: 100px;
        margin: 0 auto 12px;
        width: 100px;
      }
      &__details {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      &__list {
        position: relative;
        &.deactivate {
          .sample-select-button {
            cursor: default;
            opacity: 0.3;
            &.selected {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
        .color {
          margin-bottom: 10px
        }
        .swatch {
          border-radius: 25px;
          display: inline-block;
          height: 15px;
          margin-top: 2px;
          vertical-align: top;
          width: 15px;
        }
        &-item {
          display: flex;
          flex-direction: column;
          height: auto;
          padding: 0 10px 4px;
          text-align: center;
        }
        &-header {
          margin-bottom: 16px;
          min-height: 30px;
        }
        &-button {
          margin-top: auto;
          min-width: auto;
          width: 100%;
        }
        &-checkbox {
          display: none;
        }
        &-size {
          margin-bottom: 4px;
          margin-top: auto;
          & ~ .samples__list-button {
            margin-top: 0;
          }
        }
        &.slick-initialized {
          display: block;
        }
        .slick-list {
          margin: 0 16px;
          overflow: hidden;
        }
        .slick-track {
          display: flex;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
      }
      .slick-next {
        #{$rdirection}: 0;
      }
      .slick-dots {
        bottom: -20px;
      }
    }
  }
}
