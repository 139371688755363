body[data-checkout-release='2'] {
  .pg_wrapper--viewcart {
    .donations {
      border-width: 1px 0;
      margin-bottom: 16px;
      padding: 16px;
      @include breakpoint($medium-up) {
        border-width: 1px;
        padding: 16px;
      }
      .content {
        padding: 20px 0;
        @include breakpoint($medium-up) {
          display: flex;
        }
      }
      .logo-part {
        padding: 10px;
        vertical-align: top;
      }
      .panel-text {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 85%;
        }
      }
      .donation {
        @include breakpoint($medium-up) {
          display: flex;
        }
        &-group {
          display: flex;
        }
        &_info_panel {
          padding-top: 0;
        }
        &-learn-more {
          display: flex;
          margin-top: 20px;
          width: 100px;
        }
        &-amount-select {
          display: flex;
        }
        &_message {
          display: block;
        }
      }
      .picker-radio {
        &.picker {
          padding-#{$rdirection}: 2px;
          .picker-handle {
            margin-#{$rdirection}: 7px;
            .picker-flag {
              display: block;
              margin: 2px;
              padding: 5px;
            }
          }
        }
      }
      .button {
        height: 40px;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 110px;
        }
      }
    }
  }
}
