body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    .sign-in-panel {
      margin-bottom: 90px;
      .title--h2 {
        margin-bottom: 40px;
      }
      .toggle-user {
        @include breakpoint($medium-up) {
          display: none;
        }
        .new-customer {
          position: relative;
        }
        .returning-customer {
          margin-top: 15px;
          position: relative;
        }
        &__input {
          border-radius: 50%;
          height: 15px;
          #{$ldirection}: 0;
          position: relative;
          top: 2px;
          width: 15px;
          & ~ .label {
            padding-#{$ldirection}: 22px;
            &::before {
              display: inline-block;
              height: 16px;
              #{$ldirection}: 0;
              position: absolute;
              top: 4px;
              width: 16px;
            }
          }
        }
      }
      .panel-group {
        @include breakpoint($medium-up) {
          display: flex;
          justify-content: space-between;
        }
        .return-user {
          display: none;
          margin-top: 30px;
          @include breakpoint($medium-up) {
            display: block;
            margin-top: 0;
            padding-#{$ldirection}: 20px;
            width: 50%;
          }
          .title--h5 {
            display: none;
            @include breakpoint($medium-up) {
              display: inherit;
              margin-bottom: 20px;
            }
          }
        }
        .new-account {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
            padding-#{$rdirection}: 20px;
            width: 50%;
          }
          .title--h5 {
            display: none;
            @include breakpoint($medium-up) {
              display: inherit;
              margin-bottom: 40px;
            }
          }
        }
        .form-item {
          margin: 35px 0;
          input {
            height: 40px;
            width: 100%;
          }
        }
        .tooltip {
          margin-bottom: 30px;
        }
        .forgot-pw-note {
          margin-top: 35px;
        }
        .button {
          width: 100%;
        }
        .error-messages {
          margin-bottom: 35px;
        }
        .user-panel--active {
          display: block;
        }
      }
      .messages {
        margin-bottom: 25px;
      }
    }
    .toggle-password {
      bottom: 12px;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      #{$rdirection}: 12px;
    }
  }
}
