body[data-checkout-release='1'] {
  .pg_wrapper {
    .offer-code {
      margin-top: 20px;
      padding-top: 20px;
      &__header-text,
      &__label {
        display: block;
        margin-bottom: 4px;
      }
      &__applied {
        margin-bottom: 8px;
        &-content {
          align-items: center;
          display: flex;
          min-height: 44px;
        }
      }
      &__byline {
        margin-bottom: 0;
        margin-#{$rdirection}: 8px;
      }
      &__form-item {
        margin-bottom: 8px;
      }
      &__input-wrapper {
        display: flex;
      }
      &__input {
        margin-#{$rdirection}: 16px;
        width: 100%;
      }
      &__button {
        min-width: auto;
        padding: 15px 26px 13px;
      }
      &__field-note {
        &:first-of-type {
          margin-bottom: 12px;
        }
      }
      &__edit,
      &__remove {
        background-color: transparent;
        background-position: 50%;
        background-repeat: no-repeat;
        border: 0;
        cursor: pointer;
        height: 28px;
        padding: 0;
        text-indent: -999rem;
        width: 28px;
      }
      &__applied,
      &__form-item {
        &--hidden {
          display: none;
        }
      }
    }
  }
}
body[data-checkout-release='2'] {
  .pg_wrapper {
    .offer-code {
      margin-top: 20px;
      padding-top: 20px;
      &__header-text {
        display: block;
        margin-bottom: 4px;
      }
      &__label {
        display: inline;
        margin: 13px 0 4px -180px;
        opacity: 0.3;
        overflow: hidden;
        padding: 0;
      }
      &__applied {
        margin-bottom: 8px;
        &-content {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          min-height: 44px;
        }
      }
      &__byline {
        background: url('#{$base-theme-path}/img/icons/src/green-tick.svg') no-repeat 0 50%;
        color: $color-light-green;
        margin: 0 8px 0 0;
        padding: 5px 0 5px 30px;
      }
      &__value {
        text-transform: uppercase;
      }
      &__form-item {
        margin: 16px 0;
        &--hidden + .field-note {
          display: block;
          padding-#{$ldirection}: 35px;
        }
      }
      &__button {
        margin-#{$ldirection}: auto;
      }
      &__input-wrapper {
        display: flex;
      }
      &__input {
        height: 44px;
        margin-#{$rdirection}: 16px;
        width: 60%;
        &.adpl-label-mode + .offer-code__label {
          background: $color-white;
          height: 12px;
          margin: -7px 0 4px -190px;
          opacity: 1;
          padding: 0 6px;
        }
      }
      &__button {
        height: 44px;
        margin-top: 0;
        min-width: 106px;
        padding: 15px 26px 13px;
      }
      &__field-note {
        display: none;
      }
      &__remove {
        border: 0;
        text-decoration: underline;
        text-transform: uppercase;
      }
      &__edit {
        display: none;
      }
    }
  }
}
