body[data-checkout-release='1'] {
  .pg_wrapper--viewcart {
    .gift-options-panel {
      border-width: 1px 0;
      margin-bottom: 40px;
      padding: 16px 0;
      @include breakpoint($medium-up) {
        border-width: 1px;
        padding: 16px;
      }
      .checkout-subheading {
        background: 0;
        border: none;
        float: none;
        margin: 0;
        padding: 0;
        &.active {
          border: none;
          .accordion-icon::after {
            visibility: hidden;
          }
        }
        .title--h5 {
          display: flex;
          justify-content: space-between;
        }
        .accordion-icon {
          cursor: pointer;
          margin-#{$rdirection}: 10px;
          position: relative;
          &::before,
          &::after {
            #{$ldirection}: 50%;
            content: '';
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
          }
          &::before {
            height: 2px;
            width: 16px;
          }
          &::after {
            height: 16px;
            visibility: visible;
            width: 2px;
          }
        }
      }
      .gift-options-container {
        display: flex;
        justify-content: space-between;
        .form-item {
          margin-bottom: 20px;
          margin-#{$rdirection}: 0;
          width: 48%;
          .full {
            margin: 0;
            min-height: 45px;
            opacity: 1;
            resize: none;
            width: 100%;
          }
        }
      }
      .adpl {
        .gift-text-head {
          text-transform: uppercase;
        }
      }
      .gift-max-char {
        margin: 0;
        text-align: #{$rdirection};
        text-transform: lowercase;
      }
      .gift-options {
        margin-top: 16px;
        .gift-options__checkbox {
          display: none;
          & ~ .label {
            padding: 0;
          }
          &:checked ~ .label {
            .gift {
              &-yes {
                display: inline-block;
              }
              &-no {
                display: none;
              }
            }
            &::after {
              #{$ldirection}: 19px;
            }
          }
        }
        .label {
          #{$ldirection}: 2px;
          cursor: pointer;
          display: block;
          height: 20px;
          top: 2px;
          width: 36px;
          z-index: -9999px;
          .label-content {
            margin-#{$ldirection}: 45px;
            .gift-yes {
              display: none;
            }
            .gift-no {
              display: inline-block;
            }
          }
          &::before {
            display: none;
          }
          &::after {
            #{$ldirection}: 2px;
            border: none;
            border-radius: 90px;
            content: '';
            height: 16px;
            opacity: 1;
            position: absolute;
            top: 2px;
            transition: all $default-transition-duration ease;
            transform: scale(1);
            width: 16px;
          }
        }
      }
      .gift-options-message {
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
      .giftwrap {
        &-trigger {
          display: none;
        }
        &-group {
          display: inline-block;
          margin-#{$ldirection}: 20px;
          &:first-child {
            margin-#{$ldirection}: 0;
          }
        }
        .gift__img {
          margin-#{$rdirection}: 24px;
          width: 30%;
          @include breakpoint($medium-up) {
            width: 11%;
          }
        }
        .gift__content {
          margin-top: 16px;
        }
        .sub-section {
          display: flex;
          flex-direction: column;
          width: 100%;
          b {
            margin-bottom: 10px;
          }
          br {
            display: none;
          }
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 100px;
            width: calc(100% - 105px);
          }
          .btn {
            font-weight: 700;
            height: 48px;
            min-width: 150px;
            text-transform: uppercase;
            top: 0;
            width: auto;
          }
          .form-item {
            margin: 10px 0;
            padding-bottom: 0;
            width: 100%;
            .full {
              display: unset;
              margin: 0;
              min-height: 45px;
              opacity: 1;
              resize: none;
              width: 100%;
              & + .offer-code__label {
                margin-bottom: 0;
                pointer-events: none;
              }
              &.label-mode + .offer-code__label::before {
                line-height: 1;
                padding: 0 1.5px;
                transform: translateY(-1.5em) translateY(-2.5px) scale(0.8, 0.8);
                @include breakpoint($medium-up) {
                  transform: translateY(-1.5em) translateY(1px) scale(0.8, 0.8);
                }
              }
            }
          }
          .gift_message__title {
            display: none;
          }
        }
      }
      .form-submit {
        text-align: #{$rdirection};
        width: 50%;
      }
    }
  }
}
body[data-checkout-release='2'] {
  .pg_wrapper--viewcart {
    .gift-options-panel {
      border-width: 1px 0;
      margin-bottom: 16px;
      padding: 16px;
      @include breakpoint($medium-up) {
        border-width: 1px;
        padding: 16px;
      }
      .gift-options-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-item {
          margin-bottom: 20px;
          margin-#{$rdirection}: 0;
          width: 48%;
          .full {
            margin: 0;
            min-height: 45px;
            opacity: 1;
            resize: none;
            width: 100%;
          }
        }
      }
      .adpl {
        .gift-text-head {
          text-transform: uppercase;
        }
      }
      .gift-options {
        margin: 15px 40px 15px 0;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 60px;
        }
        .gift-options__checkbox {
          display: none;
          & ~ .label {
            background: $color-black;
            border-radius: 20px;
            height: 20px;
            padding: 0;
            position: relative;
            width: 40px;
          }
          &:checked {
            & ~ .label {
              background: $color-light-green;
              .gift {
                &-yes {
                  display: inline-block;
                }
                &-no {
                  display: none;
                }
              }
              &::after {
                #{$ldirection}: 15px;
              }
            }
          }
        }
        .label {
          cursor: pointer;
          display: block;
          height: 20px;
          top: 2px;
          width: 36px;
          z-index: -9999px;
          #{$ldirection}: 2px;
          .label-content {
            margin-#{$ldirection}: 45px;
            .gift-yes {
              display: none;
            }
            .gift-no {
              display: inline-block;
            }
          }
          &::before {
            display: none;
          }
          &::after {
            #{$ldirection}: 1px;
            background: $color-white;
            border-radius: 90px;
            border: 1px solid $color-lighter-gray;
            content: '';
            height: 16px;
            opacity: 1;
            position: absolute;
            top: 1px;
            transform: scale(1);
            transition: all $default-transition-duration ease;
            width: 16px;
          }
        }
      }
      .gift-options-message {
        text-transform: uppercase;
      }
      .giftwrap {
        &-trigger {
          display: none;
        }
        &-group {
          display: inline-block;
          margin-#{$ldirection}: 20px;
          &:first-child {
            margin-#{$ldirection}: 0;
          }
        }
        .gift {
          &__img {
            margin-#{$rdirection}: 24px;
            width: 30%;
            @include breakpoint($medium-up) {
              width: 11%;
            }
          }
          &__content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            width: 60%;
            @include breakpoint($medium-up) {
              width: auto;
            }
            &--title {
              width: 100%;
            }
          }
        }
        .giftwrap {
          &__message-success {
            background: url('#{$base-theme-path}/img/icons/src/green-tick.svg') no-repeat 0 50%;
            color: $color-light-green;
            padding: 18px 0 0 30px;
          }
          &-edit-content {
            margin-bottom: 20px;
          }
          &-edit-label {
            font-weight: bold;
          }
          &-edit-link {
            cursor: pointer;
            padding-#{$ldirection}: 10px;
            text-decoration: underline;
            text-transform: capitalize;
          }
        }
        .sub-section {
          display: flex;
          flex-direction: column;
          width: 100%;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 100px;
            width: calc(100% - 105px);
          }
          .label {
            #{$ldirection}: 1px;
            display: inline-block;
            margin-top: 10px;
            padding: 0 12px;
            position: absolute;
          }
          .adpl-label-mode + .label {
            #{$ldirection}: 10px;
            background: $color-white;
            margin-top: -10px;
            padding: 0 6px;
          }
          .card-message {
            .field {
              border-radius: 10px;
              &.error {
                border-color: $red;
              }
            }
          }
          .btn {
            font-weight: 700;
            height: 45px;
            min-width: 100px;
            text-transform: uppercase;
            top: 0;
            width: 100%;
            &:hover {
              color: $color-black;
              text-decoration: none;
            }
            @include breakpoint($medium-up) {
              width: auto;
            }
          }
          .form-item {
            margin: 10px 0;
            padding-bottom: 0;
            position: relative;
            width: 100%;
            .full {
              display: unset;
              margin: 0;
              min-height: 45px;
              opacity: 1;
              resize: none;
              width: 100%;
              & + .offer-code__label {
                margin-bottom: 0;
                pointer-events: none;
              }
              &.label-mode + .offer-code__label::before {
                line-height: 1;
                padding: 0 1.5px;
                transform: translateY(-1.5em) translateY(-2.5px) scale(0.8, 0.8);
                @include breakpoint($medium-up) {
                  transform: translateY(-1.5em) translateY(1px) scale(0.8, 0.8);
                }
              }
            }
          }
        }
      }
      .form-submit {
        text-align: #{$rdirection};
        width: 50%;
      }
    }
  }
}
