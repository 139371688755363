body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    .cart-convenience-panel {
      margin: 0;
      padding: 0;
      .convenience-panel__header {
        display: none;
      }
      .cp_content {
        margin-top: 15px;
        padding-#{$ldirection}: 5px;
        padding-#{$rdirection}: 5px;
        .convenience_panel_wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          &::before {
            display: none;
          }
          .convenience_link {
            font-size: 12px;
            .convenience_panel_label {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
