body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    .need-help-panel {
      display: block;
      margin: 20px 0;
      padding-top: 20px;
      .customer-service-content {
        margin-top: 10px;
      }
      .links_list {
        margin-top: 27px;
        .link {
          display: block;
          margin-bottom: 12px;
        }
      }
    }
  }
}

body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    .need-help-panel {
      padding: 16px;
      @include breakpoint($medium-up) {
        padding: 16px 0;
      }
      .need-help-panel__header,
      .customer-service-content,
      .content,
      .links_list,
      .title--h5,
      .link {
        display: inline;
      }
      .check_out_chat {
        a {
          text-decoration: underline;
        }
      }
      .title--h5 {
        text-transform: uppercase;
      }
      .link {
        margin: 0;
        padding: 0;
        position: initial;
        text-transform: inherit;
      }
    }
  }
}