body[data-checkout-release='1'],
body[data-checkout-release='2'] {
  .payment-cards {
    display: none;
    @include breakpoint($medium-up) {
      display: flex;
      gap: 8px;
      justify-content: center;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
    &__item {
      @include breakpoint($medium-up) {
        background-size: 46px 32px;
        height: 32px;
        text-indent: -999rem;
        width: 46px;
      }
    }
  }
  &.viewcart {
    .checkout-buttons-panel--mobile-tray {
      .payment-cards {
        display: flex;
        gap: 2px;
        justify-content: center;
        padding-bottom: 15px;
        text-align: center;
        &__item {
          background-repeat: no-repeat;
          background-size: 36px 18px;
          height: 20px;
          text-indent: -999rem;
          width: 46px;
        }
      }
    }
  }
}

body[data-checkout-release='2'] {
  .payment-cards {
    gap: 15px;
    margin-bottom: 0;
    padding-bottom: 15px;
    &__item {
      height: 20px;
      width: 30px;
    }
  }
}