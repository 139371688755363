body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1024px;
    padding: 0 20px;
    width: 100%;
    &::after {
      content: '.';
      clear: both;
      display: block;
      height: 0;
      visibility: hidden;
    }
    @include breakpoint($medium-up) {
      display: flex;
      max-width: 1230px;
      padding: 0 40px;
    }
    label {
      font-weight: normal;
    }
    fieldset {
      border: none;
      border-width: 0;
      margin: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ol {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    img {
      max-width: 100%;
      vertical-align: middle;
    }
    .column {
      &.#{$ldirection} {
        @include breakpoint($medium-up) {
          width: 70%;
        }
      }
      &.#{$rdirection} {
        @include breakpoint($medium-up) {
          width: 30%;
        }
      }
    }
  }
  .clearpay-overlay-container {
    width: 100vw;
    height: 100vh;
    @include breakpoint($medium-up) {
      width: 850px;
      height: 660px;
    }
  }
  .loading {
    * {
      pointer-events: none;
    }
  }
}
body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1024px;
    padding: 0;
    width: 100%;
    &::after {
      content: '.';
      clear: both;
      display: block;
      height: 0;
      visibility: hidden;
    }
    @include breakpoint($medium-up) {
      display: flex;
      flex-wrap: wrap;
      max-width: 1230px;
      padding: 0 40px;
    }
    label {
      font-weight: normal;
    }
    fieldset {
      border: none;
      border-width: 0;
      margin: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ol {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    img {
      max-width: 100%;
      vertical-align: middle;
    }
    .messages {
      background: none;
      border: 0;
      padding: 0;
      .single-message {
        margin-top: 10px;
        padding: 20px 10px 20px 45px;
      }
    }
    .column {
      &.#{$ldirection} {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 60px;
          width: 70%;
        }
      }
      &.#{$rdirection} {
        @include breakpoint($medium-up) {
          width: 30%;
        }
      }
    }
    .messages {
      .close_link {
        display: none;
      }
    }
  }
  .checkout-subheading {
    border: none;
    margin: 0;
    padding: 0;
    &.active {
      border: none;
      .accordion-icon {
        background: url('#{$base-theme-path}/img/icons/src/icon--minus.svg') no-repeat 0 50%;
        background-size: contain;
        height: 15px;
        width: 15px;
      }
    }
    .title--h5 {
      display: flex;
      justify-content: space-between;
    }
    .accordion-icon {
      background: url('#{$base-theme-path}/img/icons/src/icon--plus.svg') no-repeat 0 50%;
      background-size: contain;
      height: 15px;
      width: 15px;
      &::before,
      &::after {
        content: '';
        color: transparent;
      }
    }
  }
  .slick-dots {
    li {
      display: inline-block;
    }
  }
  .slick-prev,
  .slick-next {
    &.slick-disabled {
      opacity: 0.25;
      pointer-events: none;
    }
    &::before {
      opacity: 0;
    }
  }
  .overlay-container {
    padding: 20px;
    position: absolute;
    z-index: 2001;
    .close-link {
      #{$rdirection}: 10px;
      cursor: pointer;
      display: block;
      height: 40px;
      overflow: hidden;
      padding-#{$ldirection}: 20px;
      position: absolute;
      top: 10px;
      width: 15px;
    }
  }
  .overlay-background {
    #{$ldirection}: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
  }
  .clearpay-overlay-container {
    width: 100vw;
    height: 100vh;
    @include breakpoint($medium-up) {
      width: 850px;
      height: 660px;
    }
  }
  .loading {
    * {
      pointer-events: none;
    }
  }
}
