body[data-checkout-release='1'],
body[data-checkout-release='2'] {
  .pg_wrapper--viewcart {
    .checkout-buttons-panel {
      margin-top: 72px;
      .continue-checkout {
        display: block;
        height: 40px;
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .viewcart-buttons-panel {
      .continue-buttons {
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        visibility: hidden;
      }
    }
  }
  &.viewcart {
    padding-bottom: 160px;
    @include breakpoint($medium-up) {
      padding-bottom: 0;
    }
    &.sticky-checkout-cta {
      @include breakpoint($medium-up) {
        overflow-x: initial;
      }
      .sticky-checkout-panel {
        @include breakpoint($medium-up) {
          position: sticky;
          top: 0;
        }
      }
      .checkout-buttons-panel {
        @include breakpoint($medium-up) {
          margin-top: 0;
          padding-top: 72px;
        }
      }
      .column-right__content {
        display: unset;
      }
    }
    .checkout-buttons-panel {
      &--mobile {
        bottom: 0;
        #{$ldirection}: 0;
        margin: 0;
        padding: 20px;
        position: fixed;
        #{$rdirection}: 0;
        z-index: 1010;
        .checkout-button-mobile {
          margin: 0 auto;
          max-width: 400px;
        }
        .continue-checkout {
          display: block;
          height: 40px;
          line-height: 1;
          padding: 14px 20px 12px;
          &__lock--icon {
            margin-#{$rdirection}: 10px;
            padding-bottom: 5px;
          }
        }
        &-tray {
          bottom: 0;
          display: none;
          margin-bottom: 0;
          padding: 20px;
          position: fixed;
          width: 100%;
          z-index: 1111;
          .continue-checkout {
            display: block;
            height: 45px;
            margin-bottom: 20px;
            &__lock--icon {
              margin-#{$rdirection}: 10px;
              padding-bottom: 5px;
            }
          }
        }
      }
      .order-total {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
      }
    }
    .paypal-wrapper {
      padding-bottom: 10px;
    }
    .paypal-applepay-terms {
      @include breakpoint($medium-up) {
        padding-bottom: 20px;
      }
    }
    .bg-shadow {
      background-color: $color-black;
      bottom: 0;
      display: none;
      #{$ldirection}: 0;
      opacity: 0.5;
      position: fixed;
      #{$rdirection}: 0;
      top: 0;
      z-index: 1001;
    }
  }
}

body[data-checkout-release='2'] {
  &.viewcart {
    .checkout-buttons-panel {
      .order-total {
        line-height: get-line-height(18px, 18px);
        padding-bottom: 15px;
        &__value {
          font-weight: bold;
        }
      }
      .continue-checkout {
        height: 45px;
        letter-spacing: 1px;
        line-height: get-line-height(13px, 13px);
        margin-bottom: 15px;
        padding: 12px 20px;
        &__lock--icon {
          bottom: 2px;
          margin-#{$rdirection}: 8px;
          position: relative;
        }
      }
      &--mobile {
        &-tray {
          .continue-checkout {
            display: block;
            &__lock--icon {
              margin-#{$rdirection}: 10px;
            }
          }
        }
      }
    }
    .paypal-wrapper {
      /* !important used to override 3rd party inline styling */
      .zoid-outlet {
        width: 100% !important;
        @include breakpoint($medium-up) {
          width: 99.8% !important;
        }
      }
    }
    .paypal-applepay-terms,
    .standard_delivery_terms {
      letter-spacing: 0.3px;
      line-height: get-line-height(13px, 19px);
      padding-bottom: 20px;
      &__link {
        text-transform: inherit;
      }
    }
  }
}
