body[data-checkout-release='1'],
body[data-checkout-release='2'] {
  .pg_wrapper--checkout {
    .giftwrap {
      display: flex;
      flex-wrap: wrap;
      .gift_message {
        flex: 0 0 100%;
      }
    }
  }
}
