body[data-checkout-release='2'] {
  &.viewcart {
    .recommended {
      &-products-panel {
        display: block;
        padding: 24px 8px;
        margin: 0 auto;
        max-width: 1230px;
        @include breakpoint($medium-up) {
          padding: 24px 20px;
        }
        .panel__header {
          padding: 24px 8px;
          @include breakpoint($medium-up) {
            padding: 24px 10px;
          }
          .title--h2 {
            letter-spacing: 0;
            text-transform: uppercase;
          }
        }
        .slick-prev {
          #{$ldirection}: 5px;
          @include breakpoint($medium-up) {
            #{$ldirection}: 15px;
          }
        }
        .slick-next {
          #{$rdirection}: -5px;
          @include breakpoint($medium-up) {
            #{$rdirection}: 0;
          }
        }
        .slick-dots {
          bottom: 0;
        }
      }
      &-product-items {
        display: block;
        overflow: hidden;
        padding-bottom: 20px;
        width: 100%;
      }
      &-item {
        display: flex;
        flex-direction: column;
        height: auto;
        margin: 5px;
        padding: 10px;
        position: relative;
        text-align: center;
        @include breakpoint($medium-up) {
          margin: 10px;
        }
        .thumb {
          border-bottom: none;
        }
        img {
          margin: 0 auto;
          width: auto;
        }
        .description {
          .info {
            min-height: 80px;
            @include breakpoint($medium-up) {
              min-height: 60px;
            }
          }
        }
        .sku_select {
          background-color: inherit;
          background-position: 85%;
          border: none;
          padding: 10px;
          text-align: center;
        }
        .sku-price {
          height: 86px;
          padding: 10px;
        }
        .page-product {
          height: 86px;
          margin: 0 auto;
          padding: 8px;
          width: 150px;
          .shade-list {
            margin-bottom: 0;
          }
          .slick-prev {
            #{$ldirection}: -15px;
          }
          .slick-next {
            #{$rdirection}: -25px;
          }
        }
        .swatch {
          border-radius: 25px;
          display: inline-block;
          height: 24px;
          margin: 7px 0 10px;
          vertical-align: top;
          width: 24px;
          &.selected {
            &::after {
              #{$ldirection}: 2px;
              border: 1px solid $color-black;
              border-radius: 25px;
              content: '';
              height: 28px;
              position: absolute;
              top: 4px;
              width: 28px;
            }
          }
        }
        .addtobag {
          .btn {
            border-radius: 24px;
            color: $color-black;
            height: 40px;
            line-height: get-line-height(13px, 15px);
            padding: 14px;
            width: 100%;
            @include breakpoint($medium-up) {
              width: 125px;
            }
          }
          .cart-item__form {
            display: block;
          }
        }
        .price-range {
          padding-bottom: 20px;
        }
      }
    }
  }
}
